import { Layout } from '@components/layout';
import { PageTitle, ContactForm, OurShowrooms } from '@components/standard';
import type { IPage, ILocation } from '@standardTypes';
import React from 'react';

interface ContactPageProps {
  pageContext: {
    page: IPage;
  };
  location: ILocation;
}

const ContactPage: React.FC<ContactPageProps> = ({ pageContext }) => {
  const { page } = pageContext;

  return (
    <Layout {...page}>
      <PageTitle />
      <ContactForm />
      <OurShowrooms />
    </Layout>
  );
};

export default ContactPage;
